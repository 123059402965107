// todo: gdpr

import React, { useEffect, useState } from "react";
// import classNames from "classnames";
// import { m } from "framer-motion";
// import { useAppState } from "@state";
// import useGdprData from "@staticQueries/GdprQuery";
import { Script } from "gatsby";

// import { Button, Container, Icon, Text } from "@atoms";
// import gdprUtil from "@utils/gdpr";

const GdprBanner = () => {
  // const gdprCookieName = process.env.GATSBY_APP_NAME;
  // const { hasGdprConsent } = gdprUtil;

  // const hasWindow = typeof window !== "undefined";

  const [close, setClose] = useState(true); // todo: default to false
  // const dataLayer = hasWindow ? window.dataLayer || [] : [];

  // const [{ layout }, dispatch] = useAppState();
  // const { gdpr } = layout;
  // const { enabled, heading, copy, button } = useGdprData();

  // const setDoNotTrack = state => {
  //   if (hasWindow) {
  //     window.localStorage.setItem("doNotTrack", state);
  //     setClose(state);
  //   }
  //   return state;
  // };

  // useEffect(() => {
  //   if (hasWindow) {
  //     if (
  //       window.localStorage.getItem("doNotTrack") ||
  //       hasGdprConsent(gdprCookieName)
  //     ) {
  //       setClose(true);
  //     }
  //   }
  // }, []);

  // hide the GDPR banner based on GDPR acceptance
  // useEffect(() => {
  //   if (gdpr) {
  //     setClose(true);
  //     if (!dataLayer.find(v => v.gdprAccepted === true)) {
  //       dataLayer.push({
  //         event: "gdpr-accepted", // used to trigger other events in GTM, namely loading Hubspot and Google Analytics
  //         gdprAccepted: true, // used by other triggers to determine if they're allowed to run
  //         doNotTrack: window.localStorage.getItem("doNotTrack"), // used to filter out internal Cosmic traffic
  //       });
  //     }
  //   }
  // }, [gdpr]);

  // const variants = {
  //   visible: {
  //     y: "0%",
  //     opacity: 1,
  //     transition: {
  //       type: "tween",
  //       ease: "easeInOut",
  //       duration: 0.333,
  //     },
  //   },
  //   hidden: {
  //     y: "20%",
  //     opacity: 0,
  //     transition: {
  //       type: "tween",
  //       ease: "easeInOut",
  //       duration: 0.333,
  //     },
  //   },
  // };

  return (
    <>
      {close && (
        <>
          <Script
            id="gtm"
            src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`}
            strategy="idle"
            onLoad={() => {
              window.dataLayer = window.dataLayer || [];
              // window.dataLayer.push({ event: "routeChange" });
              window.dataLayer.push({ event: "gtmLoaded" });
            }}
          />
        </>
      )}
      {/* <m.div
          initial={{ y: "20%", opacity: 0 }}
          animate={close ? "hidden" : "visible"}
          variants={variants}
          className={classNames(
            "fixed inset-x-0 bottom-0 z-[100] my-4 w-full md:my-8",
            { "pointer-events-none": gdpr || close },
            { "pointer-events-auto": !gdpr || !close }
          )}
        >
          <Container>
            <div className="rounded-tl-4xl rounded-tr-4xl rounded-br-4xl bg-slate/90 p-6 shadow-xl">
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
                <div className="flex flex-grow flex-col items-baseline gap-3 text-white">
                  {heading && (
                    <Text className="mr-4 font-bold leading-snug">
                      {heading}
                    </Text>
                  )}
                  <Text
                    richText
                    className="max-w-none text-offwhite prose-p:text-sm prose-p:leading-snug prose-a:text-white hover:prose-a:text-pink hover:prose-a:transition hover:prose-a:duration-100"
                  >
                    {copy}
                  </Text>
                </div>
                <div className="flex flex-shrink-0 items-center gap-3 md:w-auto md:justify-center">
                  <Button
                    size="sm"
                    onClick={() => dispatch({ type: "acceptGdpr" })}
                  >
                    {button}
                  </Button>
                  <button
                    className="pr-4 md:pr-0"
                    type="button"
                    onClick={() => setDoNotTrack(true)}
                  >
                    <Icon
                      name="close"
                      className="h-6 w-6 text-white opacity-50 duration-200 hover:opacity-100"
                    />
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </m.div> */}
    </>
  );
};

export default GdprBanner;
